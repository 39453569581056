<!-- ========== Left Sidebar Start ========== -->


<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>


      <li class="menu-title">{{ GetWordText('blog') }}</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-tag"></i>
        <span>  {{ GetWordText('blogCat') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/blogCat/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/blogCat/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/blogCat/deleted"> {{ GetWordText('deleted') }} </a></li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-news"></i>
        <span>  {{ GetWordText('blog') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/blog/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/blog/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/blog/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>


<li class="menu-title">{{ GetWordText('services') }}</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-tag"></i>
        <span>  {{ GetWordText('servicesCat') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/servicesCat/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/servicesCat/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/servicesCat/deleted"> {{ GetWordText('deleted') }} </a></li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-devices"></i>
        <span>  {{ GetWordText('services') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/services/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/services/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/services/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('pricingTable') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/pricingTable/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/pricingTable/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/pricingTable/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-quote-right"></i>
        <span>  {{ GetWordText('servicesQuiz') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/servicesQuiz/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/servicesQuiz/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/servicesQuiz/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li class="menu-title">{{ GetWordText('ourWorks') }}</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-tag"></i>
        <span>  {{ GetWordText('ourWorksCat') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/ourWorksCat/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/ourWorksCat/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/ourWorksCat/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-image-alt"></i>
        <span>  {{ GetWordText('ourWorks') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/ourWorks/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/ourWorks/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/ourWorks/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px  bxs-image-alt"></i>
        <span>  {{ GetWordText('workGallery') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/workGallery/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/workGallery/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/workGallery/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li class="menu-title">{{ GetWordText('readyMade') }}</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-tag"></i>
        <span>  {{ GetWordText('readyMadeCat') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/readyMadeCat/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/readyMadeCat/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/readyMadeCat/deleted"> {{ GetWordText('deleted') }} </a></li>
        </ul>
      </li>



      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-cart"></i>
        <span>  {{ GetWordText('readyMade') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/readyMade/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/readyMade/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/readyMade/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('readyMadeGallery') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/readyMadeGallery/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/readyMadeGallery/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/readyMadeGallery/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>
      <li class="menu-title">{{ GetWordText('readyMade') }}</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map"></i>
        <span>  {{ GetWordText('branches') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/branches/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branches/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branches/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-building"></i>
        <span>  {{ GetWordText('partners') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/partners/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/partners/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/partners/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-copy"></i>
        <span>  {{ GetWordText('clientsLogo') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/clientsLogo/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/clientsLogo/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/clientsLogo/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('teamwork') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/teamwork/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/teamwork/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/teamwork/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('socialMedia') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/socialMedia/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/socialMedia/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/socialMedia/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('jobs') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/jobs/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/jobs/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/jobs/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('clientSay') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/clientSay/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/clientSay/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/clientSay/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>

  <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('pages') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/pages/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/pages/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/pages/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>


  <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('menu_position') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/menu_position/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/menu_position/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/menu_position/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>


  <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('menu') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/menu/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/menu/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/menu/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>



  <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('bank_accounts') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/bank_accounts/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/bank_accounts/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/bank_accounts/deleted"> {{ GetWordText('deleted') }} </a></li>
         </ul>
      </li>



    </ul>


  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
