<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm center">
            <img src="assets/logo-small.png" alt="" height="30">
          </span>
          <span class="logo-lg center">
            <img src="assets/logo-small.png" alt="" height="50">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm center">
            <img src="assets/logo-small.png" alt="" height="30">
          </span>
          <span class="logo-lg center">
            <img src="assets/logo-small.png" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->


     </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-lang-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a routerLink="dashboard/{{item.lang}}" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>


      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>



     <div class="dropdown d-inline-block" ngbDropdown>
      <button type="button" class="btn header-item noti-icon"
        ngbDropdownToggle>
        <a routerLink="knowledge">
          <i class="bx bx-support"></i>
        </a>
       </button>

    </div>


      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon"
                ngbDropdownToggle>
         <a routerLink="support/all">
           <i class="bx bx-envelope"></i>
         </a>
        </button>

      </div>



      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/{{userinfo.user.user_photo}}" alt="{{userinfo.user.name}}">
          <span class="d-none d-xl-inline-block ms-1">{{userinfo.user.name}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{GetWordText('profile')}}</a>

          <a class="dropdown-item text-danger" routerLink="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{GetWordText('logout')}}</a>
        </div>
      </div>



    </div>
  </div>
</header>
